const initialState = {
  user: null,
  UserSites: null,
  tokens: null,
  refresh_token: null,
  access_token: null,
  id_token: null,
  notification: [],
  powerbiEmbedContents: [],
  userSettings: {
    menus: [],
    landingMenuPage: null,
    role: null,
  },
  masterData: {},
};
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (!serializedState) {
      return initialState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return initialState;
  }
};

//import { createSlice } from "@reduxjs/toolkit";

const primaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_LOGGED_IN_USER":
      if (!action.payload) return state;
      state.user = action.payload;
      return state;

    case "SET_USER_SITES":
      if (!action.payload) return state;
      const sites = [...action.payload];
      const actualSites = sites.filter(
        (s) => s.sitesName !== null && s.siteName?.length > 0
      );
      state.UserSites = actualSites;
      return state;

    case "SET_COUNTRIES":
      if (!action.payload) return state;
      state.userCountries = [...action.payload];
      return state;

    case "SET_MASTER_DATA":
      if (!action.payload) return state;
      state.masterData[action.category] = action.payload;
      return state;

    case "SET_TOKEN":
      if (!action.payload) return state;
      state.tokens = action.payload;
      return state;

    case "RESET_STORE":
      state = {
        user: null,
        UserSites: null,
        tokens: null,
      };
      return state;

    case "TRIGGER_NOTIFICATION":
      if (!action.payload) return state;
      state.notification.push(action.payload);
      return state;

    case "SET_REFRESH_TOKEN":
      if (!action.payload) return state;
      state.refresh_token = action.payload;
      return state;

    case "SET_ACCESS_TOKEN":
      if (!action.payload) return state;
      state.access_token = action.payload;
      return state;

    case "SET_ID_TOKEN":
      if (!action.payload) return state;
      state.id_token = action.payload;
      return state;

    case "SET_EMBED_CONTENT":
      if (!action.payload) return state;
      state.powerbiEmbedDetails.push(action.payload);
      return state;

    case "SET_USER_MENUS":
      if (!action.payload) return state;
      state.userSettings.menus = action.payload.menus;
      state.userSettings.landingMenuPage = action.payload.landingMenuPage;
      state.userSettings.role = action.payload.role;
      return state;

    default:
      return state;
  }
};

export default primaryReducer;
