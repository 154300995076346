import { httpService } from "./httpService";
import { store } from "../StateManagement/store";
import {
  setUserSites,
  setUserMenus,
  setUserCountries,
} from "../StateManagement/actions";

class UserService {
  async getTemplate() {
    let url = `${process.env.REACT_APP_API_URL}/Energy/GetTemplateFile`;
    try {
      let { data } = await httpService.getBlob(url);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {}
  }

  async getUserCountries() {
    let url = `${process.env.REACT_APP_API_URL}/User/Countries`;
    try {
      const {
        data: { data },
      } = await httpService.get(url);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {}
  }

  async fetchUser(UID) {
    const URL = `${process.env.REACT_APP_API_URL}/User/Details/${UID}`;
    try {
      const {
        data: { data },
      } = await httpService.get(URL);
      if (data) {
        store.dispatch(setUserCountries(data));
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchSitesByUser() {
    let url = `${process.env.REACT_APP_API_URL}/User/Sites`;
    try {
      const {
        data: { data },
      } = await httpService.get(url);
      if (data) {
        store.dispatch(setUserSites(data));
        return data;
      } else {
        return null;
      }
    } catch (error) {}
  }

  async fetchSiteLogData(siteID) {
    let url = `${process.env.REACT_APP_API_URL}/Energy/GetDayLog/${siteID}`;
    try {
      const {
        data: { data },
      } = await httpService.get(url);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {}
  }

  async fetchSiteMonthAggregateLogData(siteID) {
    let url = `${process.env.REACT_APP_API_URL}/Energy/GetMonthAggregateLog/${siteID}`;
    try {
      const {
        data: { data },
      } = await httpService.get(url);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {}
  }

  async SaveEnergyDailyData(countryCode, uploadData) {
    let url = `${process.env.REACT_APP_API_URL}/Energy/DayData/${countryCode}`;
    try {
      const {
        data: { data },
      } = await httpService.post(url, uploadData);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  async MonthAggregateData(countryCode, uploadData) {
    let url = `${process.env.REACT_APP_API_URL}/Energy/MonthAggregateData/${countryCode}`;
    try {
      const {
        data: { data },
      } = await httpService.post(url, uploadData);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {}
  }

  async SaveEnergyMonthlyData(uploadData, countryCode) {
    let url = `${process.env.REACT_APP_API_URL}/Energy/MonthData/${countryCode}`;
    try {
      const {
        data: { data },
      } = await httpService.post(url, uploadData);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return error;
    }
  }

  async GetEmbeddedTokenDetailsByReportId(reportId) {
    let url = `${process.env.REACT_APP_API_URL}/Report/Token`;
    try {
      const {
        data: { data },
      } = await httpService.post(url, {
        reportId: reportId.replaceAll(" ", "+"),
      });
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {}
  }
  async fetchUserSettings() {
    let url = `${process.env.REACT_APP_API_URL}/User/Settings`;
    try {
      const {
        data: { data },
      } = await httpService.get(url);
      if (data) {
        store.dispatch(setUserMenus(data));
        return data?.landingMenuPage;
      } else {
        return null;
      }
    } catch (error) {}
  }
  getInKwh(unit = 0, uom, energyType) {
    switch (uom) {
      case "Liters": {
        if (energyType === "HeatingOil") {
          return (unit * 8.96).toString();
        } else if (energyType === "HeatingLPG") {
          return (unit * 5.89).toString();
        } else {
          return (unit * 10.5).toString();
        }
      }
      case "Cubicfeet": {
        return (unit * 0.3).toString();
      }
      case "Cubicmeter": {
        return (unit * 11.1).toString();
      }
      default: {
        return unit.toString();
      }
    }
  }
}

const userService = new UserService();

export { userService };
