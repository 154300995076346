import { httpService } from "./httpService";
import { store } from "../StateManagement/store";
import { setMasterData } from "../StateManagement/actions";

class MasterDataService {
  async getMasterData(category, id) {
    const URL = id
      ? `${process.env.REACT_APP_API_URL}/MasterData/${category}/${id}`
      : `${process.env.REACT_APP_API_URL}/MasterData/${category}`;

    try {
      const {
        data: { data },
      } = await httpService.get(URL);
      if (data) {
        store.dispatch(setMasterData(data, category));
        return data;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }
  async getStateByCountryCode(countryCode) {
    const URL = `${process.env.REACT_APP_API_URL}/MasterData/State`;
    try {
      const {
        data: { data },
      } = await httpService.post(URL, { countryCode });
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  async getSitesByStateId(stateId) {
    const URL = `${process.env.REACT_APP_API_URL}/MasterData/Sites`;
    try {
      const {
        data: { data },
      } = await httpService.post(URL, { stateId });
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }
  async getSitesBySearch(searchTerm) {
    const URL = `${process.env.REACT_APP_API_URL}/MasterData/Sites`;
    try {
      const {
        data: { data },
      } = await httpService.post(URL, { searchTerm });
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }
}

const masterDataService = new MasterDataService();

export { masterDataService };
