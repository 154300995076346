import { useState, useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { NavBar } from "./navbar/navbar";
import "./SideNavigation.css";
import { useStore } from "react-redux";
import {
  RiBarChartFill,
  RiCalendar2Line,
  RiFileExcel2Line,
  RiUserSettingsLine,
} from "react-icons/ri";

const SideNavigation = () => {
  const store = useStore();
  const landingMenuPage = store.getState().userSettings?.landingMenuPage || "";
  const [collapsed, setCollapsed] = useState(false);
  const [menus, setMenus] = useState([]);
  const [reports, setReports] = useState({});
  const [active, setActive] = useState(landingMenuPage);
  // added styles
  const styles = {
    sideBarHeight: {
      height: "90vh",
      "margin-top": "9vh",
    },
    menuIcon: {
      float: "right",
      margin: "10px",
    },
    topmenuHeight: {
      height: "10vh",
    },
  };

  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
  };

  const loadMenus = () => {
    if (store.getState().userSettings?.menus) {
      setMenus(store.getState().userSettings?.menus);
      let filterreports = store.getState().userSettings?.menus?.filter((el) => {
        return el.baseMenuName != null;
      });
      let groupreports = filterreports.reduce(function (r, a) {
        // if(r[a.baseMenuName]){
        r[a.baseMenuName] = r[a.baseMenuName] || [];
        r[a.baseMenuName].push(a);

        // }
        return r;
      }, Object.create(null));
      setReports(groupreports);
    }
  };

  useEffect(() => {
    loadMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <NavBar></NavBar>
      <ProSidebar className="sideBarHeight" collapsed={collapsed}>
        <SidebarHeader>
          <div style={styles.menuIcon} onClick={onClickMenuIcon}>
            <AiOutlineMenu />
          </div>
        </SidebarHeader>
        <div>
          <Menu
            iconShape="square"
            onClick={(e) => {
              if (e.target && e.target.href && e.target.href.length > 0) {
                setActive(e.target.href);
              } else {
                setActive("");
              }
            }}
          >
            {/* <MenuItem active={window.location.pathname == "/DataFeed/Daily"}> <img class="sideMenuIcons" src={require("../Assets/day.png")}></img> <Link to="/DataFeed/Daily" />Daily Data Feed</MenuItem> */}
            {menus.some((m) => m.url === "MonthlyDataFeed") && (
              <MenuItem
                active={active.includes("MonthlyDataFeed")}
                icon={<RiCalendar2Line />}
              >
                {" "}
                <Link to="/MonthlyDataFeed" />
                Monthly Data Feed
              </MenuItem>
            )}
            {menus.some((m) => m.url === "DataFeedExcel") && (
              <MenuItem
                active={active.includes("DataFeedExcel")}
                icon={<RiFileExcel2Line />}
              >
                {" "}
                <Link to="/DataFeedExcel" />
                Data Feed Excel
              </MenuItem>
            )}
            {menus.some((m) => m.url === "UserOnboard") && (
              <MenuItem
                active={active.includes("userOnboard")}
                icon={<RiUserSettingsLine />}
              >
                {" "}
                <Link to="/userOnboard" />
                User Onboarding
              </MenuItem>
            )}

            {reports &&
              Object.keys(reports).map((key, index) => (
                <SubMenu
                  key={index}
                  title={key}
                  icon={<RiBarChartFill></RiBarChartFill>}
                >
                  {reports[key]
                    .sort((a, b) => a["menuOrder"] - b["menuOrder"])
                    .map((report, index) => (
                      <MenuItem
                        key={index}
                        active={active.includes(report.url)}
                      >
                        {" "}
                        <Link to={"/report?id=" + report.url} />
                        {report.menuName}
                      </MenuItem>
                    ))}
                </SubMenu>
              ))}
          </Menu>
        </div>
      </ProSidebar>
    </div>
  );
};

export default SideNavigation;
