import React, { useState, useEffect } from "react";
import About from "../Admin/about";
import { EcMonthlyEntryWithExcel } from "../User/EC-DataImport";
import EcMonthlyEntry from "../User/EC-DataMonthlyEntry";
import UserOnboarding from "../Admin/UserOnboarding";
import SideNavigation from './SideNavigation';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { LoginPage } from "../Login/Login";
import { Provider } from "react-redux";
import { store } from "../StateManagement/store";
import { useStore } from "react-redux";
import PowerBiReport from "../Reports/Report";
import SessionTimeout from "./SessionTimeout";

const EcRoutes = () => {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route element={<ProtectedRoutes />}>
                        <Route exact path='/' element={< EcMonthlyEntry />}></Route>
                        <Route exact path='/about' element={< About />}></Route>
                        <Route exact path='/uploadec' element={< About />}></Route>
                        {/* <Route exact path='/DataFeed/Daily' element={<EcEntryForm />}></Route> */}
                        <Route exact path='/DataFeedExcel' element={<EcMonthlyEntryWithExcel />}></Route>
                        <Route exact path='/MonthlyDataFeed' element={<EcMonthlyEntry />}></Route>         
                        <Route exact path='/report' element={<PowerBiReport />}></Route>
                        <Route exact path='/UserOnboard' element={<UserOnboarding />}></Route>

                    </Route>
                    <Route exact path='/login' element={<LoginPage />}></Route>
                </Routes>
            </Router>
        </Provider>
    );
}

export default EcRoutes;

const ProtectedRoutes = () => {
    const store = useStore();
    const [authenticated, setAuthenticated] = useState(
        store.getState().user//?.email ? true : false
    );

    useEffect(() => {
      const authStatus = store.getState().user; //?.email ? true : false;
        setAuthenticated(authStatus);
        document.cookie =
            "ai_user=;expires=Thu 01 Jan 1970 00:00:00 UTC; path/;";
        document.cookie =
          "ai_session=;expires=Thu 01 Jan 1970 00:00:00 UTC; path/;"; 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return authenticated ? (
        <SessionTimeout>
            <SideNavigation></SideNavigation>
            <Outlet/>
        </SessionTimeout>
    ) : (<Navigate to={"/login"} />);

}

export { ProtectedRoutes }