const saveLoggedInUser = (user) => {
  return {
    type: "SAVE_LOGGED_IN_USER",
    payload: user,
  };
};

const setUserSites = (sites) => {
  return {
    type: "SET_USER_SITES",
    payload: sites,
  };
};
const setUserCountries = (countries) => {
  return {
    type: "SET_COUNTRIES",
    payload: countries,
  };
};

const setMasterData = (data, category) => {
  return {
    type: "SET_MASTER_DATA",
    payload: data,
    category,
  };
};

const setTokens = (tokens) => {
  return {
    type: "SET_TOKEN",
    payload: tokens,
  };
};
const resetStore = () => {
  return {
    type: "RESET_STORE",
    payload: null,
  };
};

const triggerNotification = (notification) => {
  return {
    type: "TRIGGER_NOTIFICATION",
    payload: notification,
  };
};
const setRefreshToken = (refresh_token) => {
  return {
    type: "SET_REFRESH_TOKEN",
    payload: refresh_token,
  };
};

const setAccessToken = (access_token) => {
  return {
    type: "SET_ACCESS_TOKEN",
    payload: access_token,
  };
};

const setIdToken = (id_token) => {
  return {
    type: "SET_ID_TOKEN",
    payload: id_token,
  };
};

const setEmbedContent = (embed_content) => {
  return {
    type: "SET_EMBED_CONTENT",
    payload: embed_content,
  };
};

const setUserMenus = (menus) => {
  return {
    type: "SET_USER_MENUS",
    payload: menus,
  };
};

export {
  saveLoggedInUser,
  setUserSites,
  setUserCountries,
  setMasterData,
  resetStore,
  setTokens,
  triggerNotification,
  setRefreshToken,
  setAccessToken,
  setIdToken,
  setEmbedContent,
  setUserMenus,
};
