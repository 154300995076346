import React from "react";

const LoginPageFooter = (props) => {
  return (
    <footer>
      <h6>
        <a
          href="https://www.shell.com/terms-of-use.html"
          target={"_blank"}
          rel="noreferrer"
          onClick={props.promptForExternalNavigation}
        >
          {" "}
          Terms & Conditions
        </a>{" "}
        <span className="seperator">|</span>{" "}
        <a
          href="https://www.shell.com/privacy.html"
          target={"_blank"}
          rel="noreferrer"
          onClick={props.promptForExternalNavigation}
        >
          Privacy Policy
        </a>
      </h6>
      <p>
        Use of, and copying from, this site is subject to our terms and
        conditions. Please read our Privacy Policy.&nbsp;&nbsp;&nbsp;
        {/* <a
          href="#"
          onClick={props.promptForExternalNavigation}
        >
          Help
        </a>&nbsp;
        |
        &nbsp;
        <a
          href="#"
          onClick={props.promptForExternalNavigation}
        >
          Contact Us
        </a> */}
      </p>
    </footer>
  );
};

export { LoginPageFooter };
