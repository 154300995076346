export default class EnergyExcelDataModel {
  SiteID = null;
  Date = null;
  InsertedDateTime = null;
  EnergyType = null;
  UOM = null;
  EnergyConsumption = null;
  EnergyConsumption_kWH = null;
  SolorEnergyGenrated = null;
  SolorEnergyUsed = null;
  SolorEnergyNetmetered = null;
  EvEnergyConsumed = null;
  EvEnergySoldCustomer = null;
}
