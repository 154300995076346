import "./EC-EntryForm.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { userService } from "../Services/userService";
import { useStore } from "react-redux";
import { useState, useEffect } from "react";
import EnergyModel from "../Services/Models/EnergyDataModel";
import { LoadingOverlay } from "../ui/LoadingOverlay";
import { toastService } from "../Services/toastService";
import addMonths from "date-fns/addMonths";
import { currencyCodes } from "../constants";

const typeUOM = {
  Electricity: "Kwh",
  Diesel: "Liters",
  NaturalGas: "Cubicmeter",
  HeatingOil: "Liters",
  HeatingLPG: "Cubicmeter",
  DistrictHeating: "Kwh",
};
const EcMonthlyEntry = () => {
  const store = useStore();
  const roleName = store.getState().userSettings.role?.name || "";
  const [currencies, setCurrencies] = useState(currencyCodes["default"]);
  const [apiProcessing, setApiProcessing] = useState(false);
  const EnergyConsumptionDefaultValue = {
    energyType: "Electricity",
    uom: "Kwh",
    unit: "",
    unitinkwh: "",
    currency: currencies[0],
    amount: "",
    amountwithoutvat: "",
  };
  const [energyConsumptionFields, setEnergyConsumptionFields] = useState([
    EnergyConsumptionDefaultValue,
  ]);
  const [solarEnergy, setsolarEnergy] = useState({
    generated: "",
    used: "",
    netMetered: "",
  });
  const [evEnergy, setEvEnergy] = useState({
    consumed: "",
    soldtoCustomer: "",
  });
  const [selectedDate, setStartDate] = useState(new Date());
  const [siteItems, setSiteItems] = useState([]);
  const [defaultSiteName, setDefaultSiteName] = useState(-1);
  const [globalSiteId, setGlobalSiteId] = useState("");
  const [siteLogData, setSiteLogData] = useState([]);
  const addFormFields = () => {
    setEnergyConsumptionFields((s) => {
      return [...s, EnergyConsumptionDefaultValue];
    });
  };

  const removeFormFields = (i) => {
    let formValues = [...energyConsumptionFields];
    formValues.splice(i, 1);
    setEnergyConsumptionFields(formValues);
  };

  let handleChange = (i, e) => {
    if (
      e.target.id === "unit" ||
      e.target.id === "amount" ||
      e.target.id === "amountwithoutvat"
    ) {
      if (!isPositiveNumber(e.target.value)) return;
    }
    let newFormValues = [...energyConsumptionFields];
    newFormValues[i][e.target.name] = e.target.value;
    if (e.target.id === "unit") {
      newFormValues[i]["unitinkwh"] = userService.getInKwh(
        e.target.value,
        newFormValues[i]["uom"],
        newFormValues[i]["energyType"]
      );
    }
    if (e.target.id === "uom") {
      newFormValues[i]["unitinkwh"] = "";
      newFormValues[i]["unit"] = "";
    }
    if (e.target.id === "energyType") {
      newFormValues[i]["uom"] = typeUOM[e.target.value];
      newFormValues[i]["unitinkwh"] = "";
      newFormValues[i]["unit"] = "";
    }
    setEnergyConsumptionFields(newFormValues);
  };

  async function sumbitform() {
    try {
      if (!isFormValid()) {
        toastService.Warning("Please enter all required fields");
        return;
      }
      setApiProcessing(true);
      let countryCode = store
        .getState()
        .UserSites.find((x) => x.globalSiteId === globalSiteId)?.countryCode;
      await userService.MonthAggregateData(countryCode, getformData());
      setApiProcessing(false);
      toastService.Success("Data Uploaded successfully");
      resetFormData();
    } catch (error) {
      setApiProcessing(false);
      toastService.Error("Data Upload failed.. Please try again.");
    }
  }
  const getUserCurrencies = (globalSiteId) => {
    const userSites = store.getState().UserSites;
    const currentSite = userSites.find(
      (site) => site.globalSiteId === globalSiteId
    );
    const countryCode = currentSite?.countryCode;
    return [...currencyCodes[countryCode], ...currencyCodes["default"]];
  };

  async function siteChange(event) {
    const currencies = getUserCurrencies(event.target.value);
    const ECF = [...energyConsumptionFields];
    const newECF = ECF.map((item) => ({ ...item, currency: currencies[0] }));
    setEnergyConsumptionFields(newECF);
    setCurrencies(currencies);
    setDefaultSiteName(event.target.value);
    if (event.target.value === "-1") {
      setGlobalSiteId("");
      return;
    }
    setGlobalSiteId(event.target.value);
    const response = await userService.fetchSiteMonthAggregateLogData(
      event.target.value
    );
    let logDataMonths = [];
    // response?.map(date => logDataMonths.push(new Date(Date.parse(date)).getMonth()));
    response?.map((date) => logDataMonths.push(date));
    setSiteLogData([...logDataMonths]);
  }

  const handleSolarEnergyData = (e) => {
    if (!isPositiveNumber(e.target.value)) return;
    let SolarEnergyData = { ...solarEnergy };
    if (e.target.id === "generated") SolarEnergyData.generated = e.target.value;
    if (e.target.id === "used") SolarEnergyData.used = e.target.value;
    if (e.target.id === "netMetered")
      SolarEnergyData.netMetered = e.target.value;
    setsolarEnergy(SolarEnergyData);
  };

  const handleEvEnergyData = (e) => {
    if (!isPositiveNumber(e.target.value)) return;
    let EvEnergyData = { ...evEnergy };
    if (e.target.id === "consumed") EvEnergyData.consumed = e.target.value;
    if (e.target.id === "soldtoCustomer")
      EvEnergyData.soldtoCustomer = e.target.value;
    setEvEnergy(EvEnergyData);
  };

  const oncalnedarOpen = () => {
    if (defaultSiteName === -1) {
      return;
    }

    let selectedyear = document.getElementsByClassName(
      "react-datepicker__header react-datepicker-year-header"
    )[0].outerText;
    let logDataMonths = [...siteLogData];
    let months = [];
    logDataMonths.forEach((x) => {
      if (x.includes(selectedyear))
        months.push(new Date(Date.parse(x)).getMonth());
    });

    let maxmonths =
      selectedyear < new Date().getFullYear() ? 11 : new Date().getMonth();
    applyStatusColurs(months, maxmonths);
  };

  // const onYearChange = () => {
  //     let selectedyear = document.getElementsByClassName('react-datepicker__header react-datepicker-year-header')[0].outerText;
  //     let logDataMonths = [...siteLogData];
  //     let months = [];
  //     logDataMonths.map(x => {
  //         if (x.includes(selectedyear))
  //             months.push(new Date(Date.parse(x)).getMonth())
  //     });

  //     let maxmonths=selectedyear<new Date().getFullYear()?11:new Date().getMonth();
  //     applyStatusColurs(months, maxmonths);

  // }

  const applyStatusColurs = (filledMonths, maxMonth) => {
    for (let i = 0; i <= 11; i++) {
      if (maxMonth < i) {
        document.getElementsByClassName(
          "react-datepicker__month-" + i
        )[0].style.color = "#ccc";
        document.getElementsByClassName(
          "react-datepicker__month-" + i
        )[0].style.backgroundColor = "#fff";
      } else {
        document.getElementsByClassName(
          "react-datepicker__month-" + i
        )[0].style.color = "white";
        document.getElementsByClassName(
          "react-datepicker__month-" + i
        )[0].style.fontWeight = "bold";
        document.getElementsByClassName(
          "react-datepicker__month-" + i
        )[0].style.borderRadius = "0.3rem";
        if (i === selectedDate.getMonth()) {
          document.getElementsByClassName(
            "react-datepicker__month-" + i
          )[0].style.borderStyle = "groove";
          document.getElementsByClassName(
            "react-datepicker__month-" + i
          )[0].style.borderColor = "#1d5d90";
        }
        if (filledMonths.includes(i)) {
          document.getElementsByClassName(
            "react-datepicker__month-" + i
          )[0].style.backgroundColor = "#FFC600";
        } else {
          document.getElementsByClassName(
            "react-datepicker__month-" + i
          )[0].style.backgroundColor = "#DD1D21";
        }
      }
    }
  };

  useEffect(() => {
    async function loadUserSitesData() {
      if (store.getState().UserSites) {
        setSiteItems(
          store.getState().UserSites.map(({ siteName, globalSiteId }) => ({
            key: siteName,
            value: globalSiteId,
          }))
        );
      } else {
        const response = await userService.fetchSitesByUser();
        if (response) {
          setSiteItems(
            response
              .filter((s) => s.sitesName !== null && s.siteName?.length > 0)
              .map(({ siteName, globalSiteId }) => ({
                key: siteName,
                value: globalSiteId,
              }))
          );
        }
      }
    }
    loadUserSitesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let isPositiveNumber = (value) => {
    //true for digits only.
    const regex = /^[0-9 .]*$/;
    return regex.test(value);
  };

  function getformData() {
    let fullData = [];
    energyConsumptionFields.forEach((item) => {
      let data = new EnergyModel();
      data.SiteID = globalSiteId;
      data.Date = selectedDate;
      data.InsertedDateTime = "";

      data.EnergyType = item.energyType;
      data.EnergyConsumption = item.unit ? item.unit : "0";
      data.UOM = item.uom;
      data.EnergyConsumption_kWH = item.unitinkwh ? item.unitinkwh : "0";
      data.Currency = item.currency;
      data.Amount = item.amount;
      data.AmountWithoutVat = item.amountwithoutvat;
      data.EvEnergyConsumed = evEnergy.consumed ? evEnergy.consumed : "0";
      data.EvEnergySoldCustomer = evEnergy.soldtoCustomer
        ? evEnergy.soldtoCustomer
        : "0";

      data.SolorEnergyGenrated = solarEnergy.generated
        ? solarEnergy.generated
        : "0";
      data.SolorEnergyNetmetered = solarEnergy.netMetered
        ? solarEnergy.netMetered
        : "0";
      data.SolorEnergyUsed = solarEnergy.used ? solarEnergy.used : "0";

      fullData.push(data);
    });
    return fullData;
  }

  function resetFormData() {
    setCurrencies(currencyCodes["default"]);
    setEnergyConsumptionFields([EnergyConsumptionDefaultValue]);
    setsolarEnergy({ generated: "", used: "", netMetered: "" });
    setEvEnergy({ consumed: "", soldtoCustomer: "" });
    setStartDate(new Date());
    setGlobalSiteId("");
    setDefaultSiteName("-1");
  }

  const isFormValid = () => {
    let isformvalid = true;
    if (!globalSiteId) {
      isformvalid = false;
    }
    energyConsumptionFields.forEach((item) => {
      if (!item.unit) {
        isformvalid = false;
      }
    });
    return isformvalid;
  };

  return (
    <div className="app-content content page">
      <div className="content-overlay"></div>
      <div className="content-wrapper">
        {/* <h3>Daily Retail Site Energy Data</h3>
                <hr></hr> */}
        {apiProcessing && <LoadingOverlay message="Please wait ..." />}
        <div className="content-header row">
          <div className="content-header-left col-md-6 col-12 mb-2">
            <h3 className="content-header-title mb-0 uppercase">
              Monthly Retail Site Energy Data
            </h3>
          </div>
        </div>
        <div className="content-body">
          {/* <-- SiteInfo--> */}
          <section className="basic-elements sectionCustom">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <fieldset className="form-group">
                            <label htmlFor="site">Site Name</label>
                            <code>*</code>
                            <br></br>
                            <select
                              onChange={(e) => siteChange(e)}
                              value={defaultSiteName}
                              className="custom-select block"
                              name="sites"
                              id="site"
                            >
                              <option value="-1">Please Select</option>
                              {siteItems.map(({ key, value }) => (
                                <option key={key} value={value}>
                                  {key}
                                </option>
                              ))}
                            </select>
                          </fieldset>
                          <div>
                            <fieldset className="form-group align-middle">
                              <label htmlFor="disabledInput">
                                Global Site Id
                              </label>
                              <br></br>
                              <label id="staticInput">{globalSiteId}</label>
                            </fieldset>
                          </div>
                        </div>
                        <div
                          style={{ display: "flex" }}
                          className="col-xl-4 col-lg-6 col-md-12 mb-1"
                        >
                          <fieldset className="form-group">
                            <label htmlFor="date">Month & Year</label>
                            <DatePicker
                              className="custom-select block testbg"
                              selected={selectedDate}
                              id="date"
                              placeholderText="Click to select a month"
                              onChange={(date) => setStartDate(date)}
                              dateFormat="MMM - yyyy"
                              showMonthYearPicker
                              disabledKeyboardNavigation
                              minDate={new Date(2021, 12, 1)}
                              maxDate={addMonths(new Date(), 0)}
                              onCalendarOpen={() => oncalnedarOpen()}
                              onYearChange={() => oncalnedarOpen()}
                              selectsDisabledDaysInRange
                            ></DatePicker>
                          </fieldset>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <div className="label-container">
                            <label>
                              <span className="EYqSq bgopenData"></span>
                              Open
                            </label>
                            <label>
                              <span className="EYqSq bgFilled"></span>
                              Filled
                            </label>
                            <label>
                              <span className="EYqSq bgCurrentData"></span>
                              Current
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <code>*</code>{" "}
                      <small>
                        <i>indicates a required field</i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <-- SiteInfo end--> */}
          {/* <--Energy Consumption--> */}
          <section className="basic-textarea- sectionCustom">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <label htmlFor="basicTextarea">
                      Monthly Energy Consumption
                    </label>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      {energyConsumptionFields.map((element, index) => (
                        <div className="row" key={index}>
                          <div className="col-xl-2 col-lg-6 col-md-12 mb-1">
                            <fieldset className="form-group">
                              <label htmlFor="energyType">Energy Type</label>
                              <br></br>
                              <select
                                className="custom-select block"
                                value={element.energyType}
                                onChange={(e) => handleChange(index, e)}
                                name="energyType"
                                id="energyType"
                              >
                                <option value="Electricity">Electricity</option>
                                <option value="Diesel">Diesel</option>
                                <option value="NaturalGas">Natural Gas</option>
                                <option value="HeatingOil">Heating Oil</option>
                                <option value="HeatingLPG">Heating LPG</option>
                                <option value="DistrictHeating">
                                  District Heating
                                </option>
                              </select>
                            </fieldset>
                          </div>
                          <div className="col-xl-1 col-lg-6 col-md-12 mb-1">
                            <fieldset className="form-group">
                              <label htmlFor="uom">UOM</label>
                              <br></br>
                              <select
                                className="custom-select block custom-select-width"
                                value={element.uom}
                                onChange={(e) => handleChange(index, e)}
                                name="uom"
                                id="uom"
                              >
                                <option value="Kwh">kWh</option>
                                <option value="Liters">Liters</option>
                                <option value="Cubicfeet">Cubic Feet</option>
                                <option value="Cubicmeter">Cubic Meter</option>
                              </select>
                            </fieldset>
                          </div>
                          <div className="col-xl-2 col-lg-6 col-md-12">
                            <fieldset className="form-group">
                              <label htmlFor="unit">Quantity </label>
                              <code>*</code>
                              <i>
                                <small className="text-muted">
                                  {" "}
                                  {element?.unitinkwh > 0
                                    ? element.unitinkwh + " (kwh)"
                                    : "(kWh)"}
                                </small>
                              </i>
                              <br></br>
                              <input
                                className="form-control"
                                placeholder="Enter Quantity"
                                type="text"
                                name="unit"
                                id="unit"
                                value={element.unit}
                                onChange={(e) => handleChange(index, e)}
                              ></input>
                            </fieldset>
                          </div>
                          <div className="col-xl-1 col-lg-6 col-md-12 mb-1">
                            <fieldset className="form-group">
                              <label htmlFor="currency">Currency</label>
                              <br></br>
                              <select
                                className="custom-select block custom-select-width"
                                value={element.currency}
                                onChange={(e) => handleChange(index, e)}
                                name="currency"
                                id="currency"
                              >
                                {" "}
                                {currencies.map((currency, index) => (
                                  <option key={index} value={currency}>
                                    {currency}
                                  </option>
                                ))}
                              </select>
                            </fieldset>
                          </div>
                          <div className="col-xl-2 col-lg-6 col-md-12">
                            <fieldset className="form-group">
                              <label htmlFor="amount">Amount </label>
                              <input
                                className="form-control"
                                placeholder="Amount"
                                type="text"
                                name="amount"
                                id="amount"
                                value={element.amount}
                                onChange={(e) => handleChange(index, e)}
                              ></input>
                            </fieldset>
                          </div>
                          <div className="col-xl-2 col-lg-6 col-md-12">
                            <fieldset className="form-group">
                              <label htmlFor="amountwithoutvat">
                                Amount without VAT
                              </label>
                              <input
                                className="form-control"
                                placeholder="Without VAT"
                                type="text"
                                name="amountwithoutvat"
                                id="amountwithoutvat"
                                value={element.amountwithoutvat}
                                onChange={(e) => handleChange(index, e)}
                              ></input>
                            </fieldset>
                          </div>

                          <div className="col-xl-1 col-lg-6 col-md-12">
                            <fieldset className="form-group">
                              <label htmlFor="delete">&nbsp;</label>
                              <br></br>
                              <button
                                id="delete"
                                type="button"
                                onClick={() => removeFormFields(index)}
                                data-repeater-delete=""
                              >
                                <img
                                  className="icon"
                                  alt="cancel button"
                                  src={require("../Assets/cancel.png")}
                                ></img>
                              </button>
                            </fieldset>
                          </div>
                        </div>
                      ))}

                      <hr></hr>
                      <div className="form-group overflow-hidden">
                        <div className="col-12">
                          <button
                            id="add-button"
                            data-repeater-create=""
                            onClick={() => addFormFields()}
                            className="btn"
                          >
                            <img
                              className="icon"
                              alt="Add Button"
                              src={require("../Assets/add.png")}
                            ></img>{" "}
                            &nbsp;&nbsp;Add &nbsp;
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <code>*</code>{" "}
                      <small>
                        <i>indicates a required field</i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <--Energy Consumption end--> */}

          {/* <--Solar Energy--> */}
          <section className="basic-textarea sectionCustom">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <label htmlFor="basicTextarea">Monthly Solar Energy </label>
                    <small className="text-muted">
                      {" "}
                      <i>(kWh)</i>
                    </small>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <fieldset className="form-group">
                            <label htmlFor="generated">Generated</label>
                            <input
                              className="form-control"
                              placeholder="Enter Generated"
                              type="text"
                              id="generated"
                              value={solarEnergy.generated}
                              onChange={(e) => handleSolarEnergyData(e)}
                            ></input>
                          </fieldset>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12">
                          <fieldset className="form-group">
                            <label htmlFor="used">Used</label>

                            <input
                              className="form-control"
                              placeholder="Enter Used"
                              type="text"
                              id="used"
                              value={solarEnergy.used}
                              onChange={(e) => handleSolarEnergyData(e)}
                            ></input>
                          </fieldset>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12">
                          <fieldset className="form-group">
                            <label htmlFor="netMetered">Net-Metered</label>
                            <input
                              className="form-control"
                              placeholder="Enter Net-Metered"
                              type="text"
                              id="netMetered"
                              value={solarEnergy.netMetered}
                              onChange={(e) => handleSolarEnergyData(e)}
                            ></input>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <--Solar Energy end--> */}
          <section className="basic-textarea sectionCustom">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <label htmlFor="basicTextarea">Monthly EV Energy</label>
                    <small className="text-muted">
                      {" "}
                      <i>(kWh)</i>
                    </small>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <fieldset className="form-group">
                            <label htmlFor="consumed">Consumed</label>
                            <input
                              className="form-control"
                              placeholder="Enter Consumed"
                              type="text"
                              id="consumed"
                              value={evEnergy.consumed}
                              onChange={(e) => handleEvEnergyData(e)}
                            ></input>
                          </fieldset>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12">
                          <fieldset className="form-group">
                            <label htmlFor="soldtoCustomer">Sold</label>
                            <br></br>
                            <input
                              className="form-control"
                              placeholder="Enter Sold to Customer"
                              type="text"
                              id="soldtoCustomer"
                              value={evEnergy.soldtoCustomer}
                              onChange={(e) => handleEvEnergyData(e)}
                            ></input>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <-- action buttons--> */}
          <section className="basic-textarea sectionCustom">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <div>
                    <div>
                      <div className="row">
                        <div className="mb-1 ml-2">
                          <fieldset className="form-group">
                            <br></br>
                            <button
                              id="submit-button"
                              onClick={() => sumbitform()}
                              type="button"
                              className="btn btn-primary"
                              disabled={["Contributor", "Admin"].includes(
                                roleName
                              )}
                            >
                              Submit
                            </button>
                          </fieldset>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 mb-1">
                          <fieldset className="form-group">
                            <br></br>
                            <button
                              id="reset-button"
                              onClick={() => resetFormData()}
                              type="button"
                              className="btn"
                            >
                              Reset&nbsp;
                            </button>
                          </fieldset>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 mb-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <-- action buttons end--> */}
        </div>
      </div>
    </div>
  );
};

export default EcMonthlyEntry;
