import "./navbar.scss";
import { useStore } from "react-redux";
import { resetStore } from "../../StateManagement/actions";
import { logService } from "../../Services/logService";
import UserManual from "../../Assets/usermanual.pdf";

export function logout(store) {
  logService.info(store.getState().user?.email + " Session end ");
  localStorage.clear();
  store.dispatch(resetStore());
  window.location.href = window.location.origin;
}
const NavBar = () => {
  const store = useStore();
  return (
    <div className="container-fluid">
      <div className="row app-navbar">
        <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 brand-section">
          <img
            src={require("../../Assets/logo.png")}
            alt="Shell Logo"
            className="logo"
          />
          <h3 className="brand-name">
            <label>Retail Site Energy & Emission Monitoring Tool</label>
          </h3>
        </div>

        <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 links-section">
          <div className="links-container">
            <div className="align-middle link">
              <img
                className="sideMenuIcons"
                src={require("../../Assets/userManual.png")}
                alt="user Manual icon"
              ></img>
              <p className="link-text">
                {" "}
                <a href={UserManual} download="UserManual.pdf">
                  User Manual
                </a>
              </p>
            </div>
            <span className="link" onClick={() => logout(store)}>
              <img
                className="sideMenuIcons"
                src={require("../../Assets/logout.png")}
                alt="Logout Icon"
              ></img>
              <p className="link-text">LogOut</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NavBar };
