import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { adminService } from "../Services/adminService";
import { toastService } from "../Services/toastService";
import "./userOnboarding.css";
import { MultiSelect } from "react-multi-select-component";

const initUser = {
  roleId: "",
  countryCode: [],
  email: "",
  name: "",
};
const initFormError = {
  roleId: false,
  countryCode: false,
  email: false,
  name: false,
};

function UserForm({ roles, countries, handleClose, show }) {
  const [user, setUser] = useState(initUser);
  const [formError, setFormError] = useState(initFormError);

  const [submitForm, setSubmitForm] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const formValidation = (value, key) => {
    const newFormError = { ...formError };
    if (
      key === "email" &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) // eslint-disable-line
    ) {
      newFormError[key] = true;
    }
    // else if (key === "name" && !/^[A-Za-z]+$/.test(value)) {
    //   newFormError[key] = true;
    // }
    else {
      newFormError[key] = false;
    }
    setFormError(newFormError);
  };

  const isDataValid = (newUser) => {
    if (
      newUser.roleId.length > 0 &&
      newUser.countryCode.length > 0 &&
      newUser.email.length > 0 &&
      newUser.name.length > 0
    ) {
      return true;
    }
    return false;
  };

  const handleFormChange = (value, key) => {
    formValidation(value, key);
    const newUser = {
      ...user,
      [key]: value,
    };
    setCanSubmit(isDataValid(newUser));
    setUser(newUser);
  };

  const handleSave = () => {
    if (canSubmit) {
      setSubmitForm(true);
    }
  };

  const handleModalClose = () => {
    setUser(initUser);
    setFormError(initFormError);
    setCanSubmit(false);
    handleClose();
  };

  useEffect(() => {
    const addUser = async () => {
      try {
        const countryCodeArray = user.countryCode
          .map((country) => country.value)
          .join(",");
        const { data, error } = await adminService.addUser({
          ...user,
          isShellUser: user.email.endsWith("@shell.com") ? 1 : 0,
          countryCode: countryCodeArray,
        });
        if (error && error.status === 409) {
          toastService.Error("User already exist.");
        } else if (data) {
          toastService.Success("User added successfully");
          handleModalClose();
        }
      } catch (err) {
        console.error(err);
        toastService.Error("Failed to add User");
      } finally {
        setSubmitForm(false);
      }
    };
    if (submitForm) {
      addUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  const countriesOptions = countries.map((country) => ({
    label: country.countryName,
    value: country.countryCode,
  }));
  return (
    <>
      <Modal show={show} onHide={handleModalClose} backdrop={false}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              <Form.Group className="mb-1" controlId="customerName">
                <Form.Label>
                  Business Customer Name <code>*</code>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => handleFormChange(e.target.value, "name")}
                />
                {formError.name && (
                  <Form.Text className="text-danger">
                    Please enter a valid name
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group className="mb-1" controlId="email">
                <Form.Label>
                  Email address <code>*</code>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => handleFormChange(e.target.value, "email")}
                />
                {formError.email && (
                  <Form.Text className="text-danger">
                    Please enter a valid email
                  </Form.Text>
                )}
              </Form.Group>
            </div>
            <div>
              <Form.Label>
                {" "}
                Country <code>*</code>
              </Form.Label>
              <MultiSelect
                className="country-select"
                options={countriesOptions}
                value={user.countryCode}
                onChange={(value) => handleFormChange(value, "countryCode")}
                labelledBy="Select"
              />
              <Form.Group className="mb-1">
                <Form.Label>
                  Role <code>*</code>
                </Form.Label>
                <Form.Select
                  onChange={(e) => handleFormChange(e.target.value, "roleId")}
                  className="custom-select"
                >
                  <option value="">Please select</option>
                  {roles &&
                    roles
                      .filter((role) => role.name !== "Retailer")
                      .map((role, index) => (
                        <option key={index} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                </Form.Select>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button id="save-button" onClick={handleSave} disabled={!canSubmit}>
            Add and Send Invite
          </Button>
          <Button id="cancel-button" onClick={handleModalClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserForm;
