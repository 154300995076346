import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin = null;
let appInsights = null;

const createTelemetryService = () => {

    const initialize = (instrumentationKey, browserHistory=null) => {

        // if (!browserHistory) {
        //     throw new Error('Could not initialize Telemetry Service');
        // }
        // if (!instrumentationKey) {
        //     throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx')
        // }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY ,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        appInsights.loadAppInsights();
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;