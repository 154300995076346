
import EcRoutes from './Layout/EcRoutes';
import { ai } from './Services/telemetryService';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

function App() {
  const styles = {
    contentDiv: {
      display: "flex",
    },
    contentMargin: {
      marginLeft: "10px",
      width: "-webkit-fill-available"
    },
  };

  useEffect(() => {
    ai.initialize(null, null);
  }, []);

  return (
    <>

      <div style={styles.contentDiv}>
        <EcRoutes></EcRoutes>
      </div>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
    </>
  );
}

export default App;

//test