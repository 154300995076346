import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import "bootstrap/dist/css/bootstrap.css";
import "./styles/global.scss";
import "./styles/bootstrap.min.css";
//import "./styles/component.min.css";
import "./styles/bootstrap-extend.min.css";
import "./styles/common.min.css";
import "./styles/bootstrap-custom.min.css";
import "./styles/colors.min.css";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
if(window.top !== window.self) {
  window.top.location.href = window.self.location.href
}

root.render(
  // <React.StrictMode>
    <App />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
