import axios from "axios";
import { store } from "../StateManagement/store";
import { logService } from "./logService";

class HttpService {
  async getBlob(url) {
    try {
      let options = {
        responseType: "blob",
        headers: {
          Authorization: this.getAuthHeader(),
          withCredentials: true,
        },
      };
      const response = await axios.get(url, options);
      return response;
    } catch (error) {
      logService.error(
        store.getState().user?.email + " Error: " + JSON.stringify(error)
      );
      throw error;
    }
  }
  async get(url) {
    try {
      let options = {
        headers: {
          Authorization: this.getAuthHeader(),
          withCredentials: true,
        },
      };
      var response = await axios.get(url, options);
      return response;
    } catch (error) {
      //alert(error.response.data);
      logService.error(
        store.getState().user?.email + " Error: " + JSON.stringify(error)
      );
      throw error;
    }
  }

  async post(URL, payload, headers = null) {
    try {
      let options = {
        headers: headers
          ? headers
          : {
              "Content-Type": "application/json",
              Authorization: this.getAuthHeader(),
              withCredentials: true,
            },
      };
      var response = await axios.post(URL, payload, options);
      return response;
    } catch (error) {
      logService.error(
        store.getState().user?.email + " Error: " + JSON.stringify(error)
      );
      throw error;
    }
  }

  async put(URL, payload, headers = null) {
    try {
      let options = {
        headers: headers
          ? headers
          : {
              "Content-Type": "application/json",
              Authorization: this.getAuthHeader(),
            },
      };
      var response = await axios.put(URL, payload, options);
      return response;
    } catch (error) {
      logService.error(
        store.getState().user?.email + " Error: " + JSON.stringify(error)
      );
      throw error;
    }
  }

  getAuthHeader() {
    const access_token = store.getState().access_token;
    const id_token = store.getState().id_token;
    return `Bearer#${access_token}#${id_token}`;
  }
}

const httpService = new HttpService();

export { httpService };
