import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Button,
  Spinner,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaFileExport } from "react-icons/fa";
import { adminService } from "../Services/adminService";
import { masterDataService } from "../Services/masterDataService";
import "./userOnboarding.css";
import { toastService } from "../Services/toastService";
import UserForm from "./UserForm";
import { useStore } from "react-redux";
import { getExcelBlob } from "../Utils/file-operations";
import { LoadingOverlay } from "../ui/LoadingOverlay";
import moment from "moment/moment";

// user status filters - shown in dropdown menu
const statusFilters = [
  { name: "Invitation Sent", id: 1 },
  { name: "Send Invitation", id: 0 },
  { name: "On-boarded", id: 2 },
];

const initFilters = {
  email: "",
  countryCode: "",
  cipmStatus: -1,
  roleId: "",
  globalSiteId: "",
};

const initUser = { id: -1, email: "", roleId: "" };
const pageSize = 20; // pagination - page size

const UserTable = () => {
  //state variables
  const store = useStore();
  const roleName = store.getState().userSettings.role?.name || "";
  const [selectedRows, setSelectedRows] = useState([]); // manage row selection
  const [data, setData] = useState([]); // all data  received from server
  const [loading, setLoading] = useState(false); // table loading state
  const [currentPage, setCurrentPage] = useState(1); // pagination - current page
  const [sendBulkInvitation, setSendBulkInvitation] = useState(false); //to useEffect for sending bulk invitations
  const [tableSelected, setTableSelected] = useState(false); // for whole table selection
  const [roleToUpdate, setRoleToUpdate] = useState(initUser); //hold the role id and role detail to be updated
  const [isSingleInvite, setSingleInvite] = useState(false); //used to handle single invitation toast message
  const [filters, setFilters] = useState(initFilters); // handle filters
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState([]);
  const [count, setCount] = useState(0);
  const [loadUsers, setLoadUsers] = useState(false);
  // Add user models
  const [show, setShow] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  //get country List
  useEffect(() => {
    const getCountries = async () => {
      try {
        if (
          store.getState().masterData &&
          store.getState().masterData.Countries
        ) {
          setCountries(store.getState().masterData.Countries);
        } else {
          const response = await masterDataService.getMasterData("Countries");
          if (response) {
            setCountries(response);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTableSelected(false);
      }
    };
    const getRoles = async () => {
      try {
        if (store.getState().masterData && store.getState().masterData.Roles) {
          setRoles(store.getState().masterData.Roles);
        } else {
          const response = await masterDataService.getMasterData("Roles");
          if (response) {
            setRoles(response);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTableSelected(false);
      }
    };
    getCountries();
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = async (limit) => {
    filters.pageNumber = currentPage;
    filters.recordsPerPage = limit;
    if (filters?.roleId?.length === 0) {
      filters.roleId = null;
    }
    const response = await adminService.getUsers({
      ...filters,
      globalSiteId:
        filters?.globalSiteId?.length === 0
          ? null
          : parseInt(filters.globalSiteId),
    });
    if (response && response.users && response.count && response.count > 0) {
      const retailer = roles.find((r) => r.name === "Retailer");
      const users = response.users.map((user) => {
        if (!user.roleId) {
          user.roleId = retailer.id;
        }
        return user;
      });
      return { users, count: response.count };
    } else return { users: [], count: 0 };
  };
  //initial data load from server and filtering the table
  useEffect(() => {
    const filterTable = async (limit) => {
      try {
        setLoading(true);
        const { users, count } = await fetchUsers(limit);
        setData(users);
        setCount(count);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
        setTableSelected(false);
        setLoadUsers(false);
        setRoleToUpdate(initUser);
      }
    };
    if (loadUsers) {
      filterTable(pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, currentPage, loadUsers]);

  // sending bulk and single Invitation

  useEffect(() => {
    const sendBulkInvite = async () => {
      try {
        const notInvitedUsers = selectedRows.filter(
          (user) => user.cipmStatus === 0 && !user.email.endsWith("@shell.com")
        );
        const response = await adminService.sendBulkInvite(notInvitedUsers);
        if (!response.error) {
          if (isSingleInvite) {
            toastService.Success("Invitation Successfully sent");
            setSingleInvite(false);
          } else toastService.Success("Bulk Invitation Successfully sent");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
        setSendBulkInvitation(false);
        setSelectedRows([]);
        setTableSelected(false);
        setLoadUsers(true);
      }
    };
    if (sendBulkInvitation) {
      sendBulkInvite();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendBulkInvitation]);

  const updateUserRole = async () => {
    try {
      toastService.Info("Updating user role ...");
      const index = data.findIndex((x) => x.email === roleToUpdate.email);
      const user = data[index];
      user.roleId = roleToUpdate.roleId;
      const response = await adminService.updateUserRole(user);
      if (!response.error) {
        toastService.Success(
          "User role updated successfully -                 Reloading to reflect changes!"
        );
        // window.location.reload(false);
      }
    } catch (err) {
      console.error(err);
      toastService.Error("Failed to update user role!!");
    } finally {
      setLoadUsers(true);
      setRoleToUpdate({ id: -1, roleId: "", email: "" });
    }
  };

  //handles the filter change for - email, country, role and status
  const handleFilterChange = (value, column) => {
    const newFilters = { ...filters, [column]: value };
    if (
      ["countryCode", "globalSiteId"].includes(column) &&
      value.length === 0
    ) {
      setLoadUsers(false);
      setFilters(newFilters);
      return;
    }
    setLoadUsers(true);
    setData([]);
    setFilters(newFilters);
    setCurrentPage(1);
  };

  // handle the entire table selection - select all data of all pages
  const handleTableSelection = (e) => {
    //if already checked
    if (e.target.checked) {
      setSelectedRows(data);
      setTableSelected(true);
    } else {
      setTableSelected(false);
      setSelectedRows([]);
    }
  };

  // handle individual row selection
  const handleRowClick = (row) => {
    let isSelected = selectedRows.find((sr) => sr.email === row.email);
    const newSelectedRowsId = isSelected
      ? selectedRows.filter((selRow) => selRow.email !== row.email)
      : [...selectedRows, row];
    setSelectedRows(newSelectedRowsId);
  };

  // handle send bulk invitation button click
  const handleBulkInviteButton = () => {
    toastService.Info("Sending Bulk Invitation");
    setLoading(true);
    setSendBulkInvitation(true);
  };

  //handle single invitation button click on each row
  const handleSingleInvitation = (row) => {
    // handle only users with Send Invitation status
    if (row.cipmStatus === 0) {
      toastService.Info("Sending Invitation to " + row.email);
      setSingleInvite(true);
      setTableSelected(false);
      setSelectedRows([row]);
      setLoading(true);
      setSendBulkInvitation(true);
    }
  };

  //handle model

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleResetButton = () => {
    setFilters(initFilters);
    setData([]);
    setSelectedRows([]);
  };

  const exportUsers = async () => {
    try {
      if (
        filters.countryCode?.length > 0 ||
        filters.email?.length > 0 ||
        filters.globalSiteId?.length > 0 ||
        filters.roleId?.length > 0 ||
        filters?.cipmStatus !== -1
      ) {
        setExportLoading(true);
        const { users } = await fetchUsers(1000); // max excel file limit 1000 users
        if (users && users.length > 0) {
          console.log({ users });
          const formattedData = users.map(
            ({
              email,
              cipmStatus,
              isShellUser,
              globalSiteId,
              siteName,
              countryCode,
            }) => {
              let status = statusFilters.find(
                (st) => st.id === cipmStatus
              )?.name;
              if (status && status === "Send Invitation") {
                status = "Not Invited";
              }
              return {
                ["Country Code"]: countryCode,
                ["Global Site Id"]: globalSiteId,
                ["Site Name"]: siteName,
                ["Email"]: email,
                ["Onboarding Status"]: isShellUser === 0 ? status : "NA",
                ["Is Shell User"]: isShellUser === 0 ? "No" : "Yes",
              };
            }
          );
          const blob = getExcelBlob(formattedData);
          const downloadLink = document.createElement("a");
          const url = URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download =
            "UsersList-" + moment().format("DD-MM-YYYY") + ".xlsx";
          downloadLink.click();
          URL.revokeObjectURL(url);
          setExportLoading(false);
        }
      } else {
        toastService.Info("Please select a filter option.");
      }
    } catch (e) {
      setExportLoading(false);
      toastService.Error("Error - exporting users to excel");
      console.log(e);
    }
  };

  const paginatedData = data.slice(0, pageSize);

  //calculate total pages - user in pagination
  const totalPages = Math.ceil(count / pageSize);

  return (
    <>
      {exportLoading && <LoadingOverlay message="Please wait ..." />}
      <div className="table-container page">
        <h3 className="content-header-title uppercase">User Onboard</h3>
        <div className="add-user">
          <Button
            id="save-button"
            onClick={handleShow}
            disabled={roleName === "Contributor"}
          >
            Add User
          </Button>
          <UserForm
            roles={roles}
            countries={countries}
            handleClose={handleClose}
            show={show}
          />
          <Button
            id="export-button"
            style={{ marginLeft: "2em" }}
            onClick={exportUsers}
            disabled={roleName === "Contributor"}
          >
            <FaFileExport />
          </Button>
        </div>
        <Table bordered hover className="table">
          <thead className="table-header">
            <tr>
              <th>
                {/* ______________checkbox on header __________-- */}
                <input
                  type="checkbox"
                  onChange={handleTableSelection}
                  checked={tableSelected}
                />
              </th>
              <th>
                {/* __________user country Select filter on header _____________ */}
                <Form.Group>
                  <Form.Label>Country </Form.Label>
                  <Form.Select
                    className="custom-select"
                    onChange={(e) =>
                      handleFilterChange(e.target.value, "countryCode")
                    }
                    defaultValue={filters.countryCode.length === 0}
                  >
                    <option value="">Please Select</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country.countryCode}>
                        {country.countryName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </th>
              <th>
                <Form.Group>
                  <Form.Label>Global Site Id</Form.Label>
                  <InputGroup>
                    <Form.Control
                      id="globalSiteId"
                      placeholder="Search..."
                      value={filters.globalSiteId}
                      style={{ borderRight: "0" }}
                      onChange={(e) => {
                        handleFilterChange(e.target.value, "globalSiteId");
                      }}
                    />
                    <InputGroup.Text
                      className="clear-button"
                      onClick={(e) => {
                        handleFilterChange("", "globalSiteId");
                      }}
                    >
                      Clear
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </th>
              <th>
                {/* __________user email search filter on header _____________ */}
                <Form.Group>
                  <Form.Label>
                    Email Id{" "}
                    {/* <img
                      className="icon"
                      src={require("../Assets/ic_search.png")}
                    ></img> */}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      id="userSearch"
                      placeholder="Search..."
                      value={filters.email}
                      style={{ borderRight: "0" }}
                      onChange={(e) => {
                        handleFilterChange(e.target.value, "email");
                      }}
                    />
                    <InputGroup.Text
                      className="clear-button"
                      onClick={(e) => {
                        handleFilterChange("", "email");
                      }}
                    >
                      Clear
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </th>

              <th>
                {/* __________user role filter on header _____________ */}
                <Form.Group>
                  <Form.Label>
                    Role{" "}
                    {/* <img
                      className="icon"
                      src={require("../Assets/ic_search.png")}
                    ></img> */}
                  </Form.Label>
                  <Form.Select
                    className="custom-select"
                    onChange={(e) =>
                      handleFilterChange(e.target.value, "roleId")
                    }
                    defaultValue={filters?.roleId?.length === 0}
                  >
                    <option value="">All</option>
                    {roles &&
                      roles.map((role, index) => (
                        <option key={index} value={role.id}>
                          {role?.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </th>
              {/* __________user status filter on header _____________ */}
              <th>
                <Form.Group>
                  <Form.Label>
                    Status{" "}
                    {/* <img
                      className="icon"
                      src={require("../Assets/ic_search.png")}
                    ></img> */}
                  </Form.Label>
                  <Form.Select
                    className="custom-select"
                    onChange={(e) =>
                      handleFilterChange(parseInt(e.target.value), "cipmStatus")
                    }
                    defaultValue={filters.cipmStatus < 0}
                  >
                    <option value={-1}>All</option>
                    {statusFilters.map((status, index) => (
                      <option key={index} value={status.id}>
                        {status.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </th>
              <th
                id="reset-th"
                className="reset-container"
                onClick={handleResetButton}
              >
                <img
                  alt="refresh icon"
                  className="icon"
                  src={require("../Assets/refresh.png")}
                ></img>
              </th>
            </tr>
          </thead>
          {loading ? (
            // ____________ Spinner before data loads______________
            <>
              <Spinner
                animation="grow"
                variant="secondary"
                size="sm"
                id="spinner1"
              />
              <Spinner
                animation="grow"
                variant="secondary"
                size="sm"
                id="spinner2"
              />
              <Spinner
                animation="grow"
                variant="secondary"
                size="sm"
                id="spinner3"
              />
            </>
          ) : (
            <tbody>
              {/* ________show if no user data_____________ */}
              {paginatedData.map((row, index) => (
                <tr key={index}>
                  {/* ______________checkbox on rows __________-- */}
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.find(
                        (sr) => sr.email === row.email
                      )}
                      onChange={() => handleRowClick(row)}
                    />
                  </td>
                  {/* for testing remove the id row */}
                  {/* <td>{row.id}</td> */}
                  <td>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          row?.countryCode
                        </Tooltip>
                      }
                    >
                      <>
                        {row?.countryCode?.length > 8
                          ? row?.countryCode?.substring(0, 8) + "..."
                          : row?.countryCode}
                      </>
                    </OverlayTrigger>
                  </td>
                  <td>{row?.globalSiteId}</td>
                  <td>{row?.email}</td>
                  <td>
                    <span>
                      <select
                        disabled={roleName === "Contributor"}
                        className={"roles-select"}
                        value={row?.role}
                        onChange={(e) =>
                          setRoleToUpdate({
                            id: index,
                            roleId: e.target.value,
                            email: row.email,
                          })
                        }
                        defaultValue={
                          roles
                            ? roles.find((role) => row?.roleId === role.id)
                                ?.id || ""
                            : ""
                        }
                      >
                        {roles &&
                          roles.map((role, index) => (
                            <option key={index} value={role.id}>
                              {role?.name}
                            </option>
                          ))}
                      </select>
                      <button
                        onClick={updateUserRole}
                        hidden={index !== roleToUpdate.id}
                        id="role-save-button"
                      >
                        Save
                      </button>
                    </span>
                  </td>
                  <td>
                    <button
                      disabled={roleName === "Contributor" || !row.email}
                      id="invite-button"
                      className={`btn ${
                        row?.email && row.email.endsWith("@shell.com")
                          ? "On-boarded"
                          : statusFilters
                              .find((sf) => sf.id === row?.cipmStatus)
                              ?.name.replaceAll(" ", "")
                      }`}
                      onClick={() => handleSingleInvitation(row)}
                    >
                      {row?.email && row.email.endsWith("@shell.com")
                        ? "On-boarded"
                        : statusFilters.find((sf) => sf.id === row?.cipmStatus)
                            .name}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {/* ______________Pagination _______________ */}
        {paginatedData?.length > 0 && (
          <>
            <div className="pagination align-items-center justify-content-end pt-1">
              <div
                className={
                  currentPage === 1
                    ? "page-link page-link-disabled"
                    : "page-link"
                }
                aria-label="Previous"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                  setLoadUsers(true);
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
              >
                <span aria-hidden="true">‹</span>
                <span className="sr-only">Previous</span>
              </div>
              <div className="mx-2">
                &nbsp; Page&nbsp;
                <span className="font-weight-bold">{currentPage}</span>
                &nbsp;of &nbsp;
                <span className="font-weight-bold">{totalPages}</span>
              </div>
              <div
                className={
                  currentPage >= totalPages
                    ? "page-link page-link-disabled"
                    : "page-link"
                }
                aria-label="Next"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  setLoadUsers(true);
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
              >
                <span aria-hidden="true">›</span>
                <span className="sr-only">Next</span>
              </div>
            </div>
            {/* ________Send Bulk Invitaion Button _____________ */}
            <Button
              disabled={roleName === "Contributor" || selectedRows.length === 0}
              variant="light"
              id="bulk-invite-button"
              onClick={handleBulkInviteButton}
              className="btn btn-primary"
            >
              Send Invitation in Bulk
            </Button>
            {/* _______________Add User Model */}
          </>
        )}
      </div>
    </>
  );
};

export default UserTable;
