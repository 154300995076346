import React, { useEffect, useState } from 'react';
import { logout } from "./navbar/navbar";
import { store } from "../StateManagement/store";

export default function SessionTimeout({ children }) {

    const [sessionExpired, setSessionExpired] = useState(false);

    useEffect(() => {
        let timeoutId;
        const resetTimeout = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(handleSessionTimeout, 30 * 60 * 1000); //30 minutes
        }

        const handleSessionTimeout = () => {
            setSessionExpired(true)
        }

        const handleUserActivity = () => {
            resetTimeout();
        }

        //attach event listeners

        document.addEventListener('mousemove', handleUserActivity);
        document.addEventListener("keydown", handleUserActivity);
        document.addEventListener("mousedown", handleUserActivity);
        document.addEventListener("touchstart", handleUserActivity);
        document.addEventListener("touchmove", handleUserActivity);

        //start initial timer
        resetTimeout();

        //clean up event listeners
        return () => {
            clearTimeout(timeoutId);
            document.removeEventListener("mousemove", handleUserActivity);
            document.removeEventListener("keydown", handleUserActivity);
            document.removeEventListener("mousedown", handleUserActivity);
            document.removeEventListener("touchstart", handleUserActivity);
            document.removeEventListener("touchmove", handleUserActivity);
        }
    }, []);
    
    if (sessionExpired) logout(store);
    return <>{children}</>
}
