import "./EC-DataImport.scss";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import { useState, useEffect, useRef } from "react";
import { useStore } from "react-redux";
import { userService } from "../Services/userService";
import EnergyExcelDataModel from "../Services/Models/EnergyExcelDataModel";
import { LoadingOverlay } from "../ui/LoadingOverlay";
import { toastService } from "../Services/toastService";
import { MyEnum } from "../Services/constants";
import Form from "react-bootstrap/Form";

const EcMonthlyEntryWithExcel = () => {
  const ref = useRef();
  const [apiProcessing, setApiProcessing] = useState(false);
  const [selectedDate, setStartDate] = useState(new Date());
  const [selectedFileData, setSelectedFileData] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("Choose file");
  const [countryCode, setCountryCode] = useState("");
  const [countries, setCountries] = useState([]);

  const store = useStore();
  const roleName = store.getState().userSettings.role?.name || "";
  useEffect(() => {
    const getUserCountries = async () => {
      try {
        if (store.getState().userCountries) {
          setCountries(store.getState().userCountries);
        } else {
          const response = await userService.getUserCountries();
          if (response) {
            setCountries(response);
          }
        }
      } catch (e) {
        console.log("Exception on getUserCountries: " + e);
      }
    };
    getUserCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFileChange = (event) => {
    var f = event.target.files[0];
    if (
      f &&
      (f.type === "application/vnd.ms-excel" ||
        f.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setSelectedFileName(f.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        /* Parse data */
        const bstr = event.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsnames = wb.SheetNames;
        var monthsData = [];

        wsnames.forEach((element) => {
          var monthData = [];
          const ws = wb.Sheets[element];
          const data = XLSX.utils.sheet_to_json(ws, { header: 0, defval: "" });
          if (data.length !== 0) {
            monthData.push({ month: element, data: data });
            monthsData.push({ month: element, data: data });
          }
        });
        setSelectedFileData(monthsData);
      };
      reader.readAsBinaryString(f);
    } else {
      setSelectedFileName("Choose file");
      event.target.value = "";
      toastService.Warning("Please select Excel file (XLS, XLSX or CSV) only.");
    }
  };

  async function submitForm() {
    try {
      if (!isFormValid()) {
        return;
      }

      setApiProcessing(true);
      let response;
      let fileData = [...selectedFileData];
      await Promise.all(
        fileData.map(async (monthdata) => {
          response = await userService.SaveEnergyMonthlyData(
            getformData(monthdata),
            countryCode
          );
        })
      );

      // fileData.forEach(async monthdata => {
      //     response = await userService.SaveEnergyMonthlyData(getformData(monthdata));
      // });

      setApiProcessing(false);
      if (response === true) {
        toastService.Success("Data Uploaded successfully");
        resetFormData();
      } else {
        toastService.Error("Data Upload failed.. Please try again.");
      }
    } catch (error) {
      setApiProcessing(false);
      toastService.Error("Data Upload failed.. Please try again.");
    }
  }

  function resetFormData() {
    ref.current.value = "";
    setStartDate(new Date());
    setSelectedFileName("Choose file");
  }

  const getOtherData = (item) => ({
    SolorEnergyGenrated: item["Solar Energy Generated (kWh)"],
    SolorEnergyUsed: item["Solar Energy Used (kWh)"],
    SolorEnergyNetmetered: item["Solar Energy Net Metered (kWh)"],
    EvEnergyConsumed: item["EV Energy Consumed (kWh)"],
    EvEnergySoldCustomer: item["EV Energy Sold (kWh)"],
  });
  function getformData(fileData) {
    let fullData = [];
    fileData.data.forEach((item) => {
      if (`${item["Global Site ID"]}`.length === 0) {
        return;
      }
      let data = new EnergyExcelDataModel();
      data.SiteID = `${item["Global Site ID"]}`;
      data.Date = new Date(
        selectedDate.getFullYear(),
        MyEnum[fileData.month],
        3
      );
      data.InsertedDateTime = new Date().toISOString();

      if (item["Electricity Consumption (kWh)"]) {
        data.EnergyType = "Electricity";
        data.EnergyConsumption = item["Electricity Consumption (kWh)"];
        data.UOM = "kWh";
        data.EnergyConsumption_kWH = userService.getInKwh(
          data.EnergyConsumption,
          data.UOM,
          data.EnergyType
        );
        fullData.push({ ...data, ...getOtherData(item) });
      }

      if (item["Diesel Consumption (Liters)"]) {
        data.EnergyType = "Diesel";
        data.EnergyConsumption = item["Diesel Consumption (Liters)"];
        data.UOM = "Liters";
        data.EnergyConsumption_kWH = userService.getInKwh(
          data.EnergyConsumption,
          data.UOM,
          data.EnergyType
        );
        fullData.push({ ...data, ...getOtherData(item) });
      }

      if (item["Heating Oil (Liters)"]) {
        data.EnergyType = "HeatingOil";
        data.EnergyConsumption = item["Heating Oil (Liters)"];
        data.UOM = "Liters";
        data.EnergyConsumption_kWH = userService.getInKwh(
          data.EnergyConsumption,
          data.UOM,
          data.EnergyType
        );
        fullData.push({ ...data, ...getOtherData(item) });
      }

      if (item["Natural Gas (Cubic Meters)"]) {
        data.EnergyType = "NaturalGas";
        data.EnergyConsumption = item["Natural Gas (Cubic Meters)"];
        data.UOM = "Cubicmeter";
        data.EnergyConsumption_kWH = userService.getInKwh(
          data.EnergyConsumption,
          data.UOM,
          data.EnergyType
        );
        fullData.push({ ...data, ...getOtherData(item) });
      }

      if (item["Heating LPG (Cubic Meters)"]) {
        data.EnergyType = "HeatingLPG";
        data.EnergyConsumption = item["Heating LPG (Cubic Meters)"];
        data.UOM = "Cubicmeter";
        data.EnergyConsumption_kWH = userService.getInKwh(
          data.EnergyConsumption,
          data.UOM,
          data.EnergyType
        );
        fullData.push({ ...data, ...getOtherData(item) });
      }

      if (item["District Heating (kWh)"]) {
        data.EnergyType = "DistrictHeating";
        data.EnergyConsumption = item["District Heating (kWh)"];
        data.UOM = "kWh";
        data.EnergyConsumption_kWH = userService.getInKwh(
          data.EnergyConsumption,
          data.UOM,
          data.EnergyType
        );
        fullData.push({ ...data, ...getOtherData(item) });
      }
    });
    return fullData;
  }

  const isFormValid = () => {
    let isformvalid = true;
    if (countryCode.length === 0) {
      toastService.Warning("Please select a country");
      isformvalid = false;
    }
    if (selectedFileName === "Choose file") {
      toastService.Warning("Please enter all required fields");
      isformvalid = false;
    } else if (selectedFileData.length === 0) {
      toastService.Warning(
        "Empty Template uploaded.Please add the consumption data."
      );
      isformvalid = false;
    }
    return isformvalid;
  };

  const handleCountryChange = (countryCode) => {
    setCountryCode(countryCode);
  };
  const handelDownloadClick = async (countryCode) => {
    const blob = await userService.getTemplate();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${countryCode}_EnergyConsumption_Template.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="app-content content page">
      <div className="content-overlay"></div>
      <div className="content-wrapper">
        {/* <h3>Daily Retail Site Energy Data</h3>
                <hr></hr> */}
        {apiProcessing && <LoadingOverlay message="Please wait ..." />}
        <div className="content-header row">
          <div className="content-header-left col-md-10 col-10 mb-2">
            <h3 className="content-header-title mb-0 uppercase">
              Energy Consumption Data feed
            </h3>
          </div>
        </div>
        <div>
          <section className="basic-elements sectionCustom">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  {/* <div className="card-header">
                                        <h4 className="card-title">Daily Retail Site Energy Data</h4>
                                    </div> */}
                  <div className="card-content">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 mb-1"></div>
                        <div className="col-xl-12 col-lg-12 col-md-12 mb-1 zindex">
                          <fieldset className="form-group">
                            <label htmlFor="date">Year</label>
                            <DatePicker
                              className="custom-select block"
                              selected={selectedDate}
                              id="date"
                              showIcon
                              placeholderText="Click to select a year"
                              onChange={(date) => setStartDate(date)}
                              dateFormat="yyyy"
                              showYearPicker
                              minDate={new Date(2022, 1, 1)}
                              maxDate={new Date()}
                            ></DatePicker>
                          </fieldset>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 mb-1 flex-country">
                          <Form.Group>
                            <Form.Label>
                              Country <code>*</code>
                            </Form.Label>
                            <Form.Select
                              onChange={(e) =>
                                handleCountryChange(e.target.value)
                              }
                              className="custom-select"
                            >
                              <option value="">Please select</option>
                              {countries &&
                                countries.length > 0 &&
                                countries.map(
                                  ({ countryCode, countryName }, index) => (
                                    <option key={index} value={countryCode}>
                                      {countryName}
                                    </option>
                                  )
                                )}
                            </Form.Select>
                          </Form.Group>
                          {countryCode.length > 0 && (
                            <div className="ml-4">
                              <fieldset>
                                <button
                                  id="download-button"
                                  onClick={() =>
                                    handelDownloadClick(countryCode)
                                  }
                                >
                                  Download Template
                                </button>
                              </fieldset>
                              <small>
                                Please use the above link to download the
                                template
                              </small>
                            </div>
                          )}
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                          <fieldset className="form-group">
                            <label htmlFor="basicInputFile">File Input</label>
                            <code>*</code>
                            <br></br>
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="inputGroupFile01"
                                onChange={(e) => onFileChange(e)}
                                ref={ref}
                                type="file"
                                accept=".xls,.xlsx,.csv,.xlsm"
                              ></input>

                              <label
                                className="custom-file-label"
                                htmlFor="inputGroupFile01"
                              >
                                {selectedFileName}
                              </label>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    <div>
                      <code>*</code>{" "}
                      <small>
                        <i>indicates a required field</i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <-- action buttons--> */}
      <section className="basic-textarea sectionCustom">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="ml-2 mb-1">
                <fieldset className="form-group">
                  <br></br>
                  <button
                    id="submit-button"
                    onClick={() => submitForm()}
                    type="button"
                    className="btn btn-primary"
                    disabled={["Contributor", "Admin"].includes(roleName)}
                  >
                    Submit
                  </button>
                </fieldset>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 mb-1">
                <fieldset className="form-group">
                  <br></br>
                  <button
                    id="reset-button"
                    onClick={() => resetFormData()}
                    type="button"
                    className="btn"
                  >
                    Reset&nbsp;
                  </button>
                </fieldset>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 mb-1"></div>
            </div>
          </div>
        </div>
      </section>
      {/* <-- action buttons end--> */}
    </div>
  );
};

export { EcMonthlyEntryWithExcel };
