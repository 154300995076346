import { toast } from 'react-toastify';

class ToastService {

    Success = (msg) => {
        toast.success(msg);
    }

    Error = (msg) => {
        toast.error(msg);
    }

    Info = (msg) => {
        toast.info(msg);
    }

    Warning = (msg) => {
        toast.warn(msg);
    }

}

const toastService=new ToastService();

export {toastService}