import primaryReducer  from "./reducer";
import { createStore } from "redux";

//const persistedState = loadState();
const store=createStore(
    primaryReducer);

    // const saveState = (state) => {
    //     try {
    //       const serializesState = JSON.stringify(state);
    //       localStorage.setItem("state", serializesState);
    //     } catch (err) {
    //     //  console.log(err);
    //     }
    //   };

    // store.subscribe(throttle(() => {
    //     saveState(store.getState());
    //   }, 10000));


export{store};