const currencyCodes = {
  AT: [],
  BE: [],
  BG: ["BGN"],
  CA: ["CAD"],
  CH: ["CHF"],
  CN: ["CNY (RMB)"],
  CZ: ["CZK"],
  DE: [],
  FR: [],
  GB: ["GBP"],
  HK: ["HKD"],
  HU: ["HUF"],
  ID: ["IDR"],
  IN: ["INR"],
  LU: [],
  MX: ["MXN"],
  MY: ["MYR"],
  OM: [],
  PH: ["PHP"],
  PK: [],
  PL: ["PLN"],
  SG: ["SGD"],
  SI: [],
  SK: [],
  TH: ["THB"],
  TR: ["TRY"],
  UA: [],
  US: ["USD"],
  ZA: ["ZAR"],
  default: ["USD","Euro"],
};
export { currencyCodes};