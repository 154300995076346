const LoadingOverlay = (props) => {
    return (
        <div id="overlay" className="LoaderZindex">
            <div style={{
                position: "relative",
                top: "40%",
                left: "50%",
                right: "50%"
            }} />
            <span id="text">{props.message || "Loading..."}</span>
        </div>
    );
};

export { LoadingOverlay };