import { getAppInsights } from "./telemetryService";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

const LogService = () => {
  const info = (message) => {
    let obj = {
      message: message,
      severityLevel: SeverityLevel.Information,
    };

    if (process.env.REACT_APP_ENV === "dev") {
      console.log(obj);
      //   return;
    }

    let insights = getAppInsights();
    if (insights) {
      insights.trackTrace(obj);
    }
  };

  const warning = (message) => {
    let obj = {
      message: message,
      severityLevel: SeverityLevel.Warning,
    };

    if (process.env.REACT_APP_ENV === "dev") {
      console.log(obj);
      return;
    }

    let insights = getAppInsights();
    if (insights) {
      insights.trackTrace(obj);
    }
  };

  const error = (error) => {
    let obj = {
      message: error,
      severityLevel: SeverityLevel.Error,
    };

    if (process.env.REACT_APP_ENV === "dev") {
      console.log(obj);
      return;
    }

    let insights = getAppInsights();
    if (insights) {
      insights.trackException(obj);
    }
  };

  const event = (name, properties) => {
    let obj = {
      name,
      properties,
    };

    if (process.env.REACT_APP_ENV === "dev") {
      console.log(obj);
      return;
    }

    let insights = getAppInsights();
    if (insights) {
      insights.trackEvent(obj);
    }
  };

  return { info, warning, event, error };
};

export const logService = LogService();
