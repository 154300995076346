import * as XLSX from "xlsx";

export const getExcelBlob = (jsonData) => {
  const worksheet = XLSX.utils.json_to_sheet(jsonData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
  const excelBuffer = XLSX.write(workbook, {
    type: "array",
    bookType: "xlsx",
  });
  return new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spredsheetml.sheet",
  });
};
