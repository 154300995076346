import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingOverlay } from "../ui/LoadingOverlay";
import { SelectionPanel } from "../ui/SelectionPanel";
import { LoginPageFooter } from "./Footer";
import { loginService } from "../Services/loginService";
import "./Login.scss";
import {
  saveLoggedInUser,
  triggerNotification,
} from "../StateManagement/actions";
import { userService } from "../Services/userService";
import { useDispatch, useStore } from "react-redux";
import { logService } from "../Services/logService";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useStore();
  const [loggingIn, setLoggingInStatus] = useState(false);
  const [showError, setShowError] = useState({ status: false, message: "" });
  const [searchParams] = useSearchParams();

  const handelSelection = (e) => {
    setShowError(false);
    const isShellUser = e;
    if (isShellUser) {
      setLoggingInStatus(true);
      loginService.shellUserLogin();
    } else {
      setLoggingInStatus(true);
      loginService.nonShellUserLogin();
    }
  };

  const promptForExternalNavigation = (e) => {
    return true;
    // const response = window.confirm(
    //   "⚠️ You will be taken to a different website. Are you sure you wish to continue ?"
    // );
    // !response && e.preventDefault();
  };

  const handelAfterRedirection = async () => {
    let landingUrl;
    const code = searchParams.get("code");
    const error = searchParams.get("error");

    const loginPayload = localStorage.getItem("loginPayload")
      ? JSON.parse(localStorage.getItem("loginPayload"))
      : null;

    if (
      !code &&
      !error &&
      loginPayload &&
      loginPayload.loginType &&
      !loginPayload.codeVerifier
    ) {
      if (loginPayload.loginType === "shell") {
        setLoggingInStatus(true);
        loginService.shellUserLogin();
      } else {
        setLoggingInStatus(true);
        loginService.nonShellUserLogin();
      }
    } else if (code && !error && loginPayload) {
      /**
       * This is the case when the user visits the portal after being redirected.
       * From here depending on what kind of user (shell/non-shell) tried logging in, the PKCE login flow will proceed.
       */
      setLoggingInStatus(true);
      const loginType = loginPayload.hasOwnProperty("loginType")
        ? loginPayload.loginType
        : null;

      const codeVerifier = loginPayload.codeVerifier
        ? loginPayload.codeVerifier
        : null;

      if (loginPayload && loginType === "shell") {
        const shellUser = await loginService.handelUserAfterPINGRedirection(
          code,
          codeVerifier
        );
        if (shellUser) {
          store.dispatch(saveLoggedInUser(shellUser));
          logService.info(store.getState().user?.email + " Session start ");
          landingUrl = await userService.fetchUserSettings();
          setLoggingInStatus(false);
        }
        if (landingUrl) {
          navigate("/" + landingUrl);
        } else {
          setLoggingInStatus(false);
          setShowError({
            status: true,
            message: "Unable to identify Shell user.",
          });
          dispatch(
            triggerNotification({
              type: {
                style: "error",
                icon: true,
              },
              message: "Unable to identify Shell user.",
            })
          );
          localStorage.clear();
        }
      } else if (loginPayload && loginType === "nonshell") {
        // This is the case when a non shell user had tried to log in.
        const nonShellUser = await loginService.handelUserAfterCIPMRedirection(
          code,
          codeVerifier
        );
        if (nonShellUser) {
          store.dispatch(saveLoggedInUser(nonShellUser));
          logService.info(store.getState().user?.email + " Session start ");
          landingUrl = await userService.fetchUserSettings();
          setLoggingInStatus(false);
        }
        if (landingUrl) {
          navigate("/" + landingUrl);
        } else {
          setLoggingInStatus(false);
          setShowError({
            status: true,
            message: "Unable to identify Non Shell user.",
          });
          dispatch(
            triggerNotification({
              type: {
                style: "error",
                icon: true,
              },
              message: "Unable to identify Non-Shell user.",
            })
          );
          localStorage.clear();
        }
      }
    } else if (!code && error) {
      /**
       * This is the case when the user visits the portal after getting redirected 
       but with some login error, mainly because of a failed login attempt.
       */
      setLoggingInStatus(false);
      setShowError({
        status: true,
        message: "It appears you're unauthorized to access this application.",
      });
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message:
            "It appears you are unauthorized to access this application.",
        })
      );
    }
  };

  // This UseEffect hook will continue the PKCE auth. after redirection
  useEffect(() => {
    handelAfterRedirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container-fluid login-page">
        <div className="row login-container">
          <div className="brand-container">
            <div className="brand-container-row">
              <img src={require("../Assets/logo.png")} alt="" />
              <h1 className="text">
                <b>Retail Site Energy & Emission Monitoring Tool</b>
              </h1>
              {loggingIn && (
                <LoadingOverlay message="Please wait while we log you in ..." />
              )}
              {showError.status && (
                <p className="error-message">{showError.message}</p>
              )}
            </div>
            <div className="login-options-row">
              <SelectionPanel
                handelSelection={handelSelection}
                text="I have a shell login ID"
                eg="Eg: John.Smit@Shell.com"
                isShellUser={true}
              />
              <SelectionPanel
                handelSelection={handelSelection}
                text="I don't have a shell login ID"
                eg="Eg: John.Smit@Gmail.com"
                isShellUser={false}
              />
            </div>
          </div>
        </div>
        <LoginPageFooter
          promptForExternalNavigation={promptForExternalNavigation}
        />
      </div>
    </>
  );
};

export { LoginPage };
