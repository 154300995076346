import { httpService } from "./httpService";

class AdminService {
  async getUsers(filters) {
    const URL = `${process.env.REACT_APP_API_URL}/User/GetUsers`;

    try {
      const {
        data: { data },
      } = await httpService.post(URL, filters);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  async sendBulkInvite(ids) {
    const URL = `${process.env.REACT_APP_API_URL}/User/SendCIPMInvitations`;

    try {
      const {
        data: { data },
      } = await httpService.post(URL, ids);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }
  async updateUserRole(roleToUpdate) {
    const URL = `${process.env.REACT_APP_API_URL}/User/Update`;
    try {
      const {
        data: { data },
      } = await httpService.put(URL, roleToUpdate);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  async addUser(users) {
    const URL = `${process.env.REACT_APP_API_URL}/User/CreateUser`;

    try {
      const {
        data: { data },
        error,
      } = await httpService.post(URL, users);
      if (data) {
        return { data, error };
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }
}

const adminService = new AdminService();

export { adminService };
