import './Report.scss';
import React, { useState, useEffect } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { userService } from '../Services/userService';
import { useLocation } from 'react-router-dom';

const PowerBiReport = () => {

    const location = useLocation();
    const [responseConfig, setResponseConfig] = useState({});
    let query = new URLSearchParams(useLocation().search);
    useEffect(() => {
      async function getEmbedData() {
        const response = await userService.GetEmbeddedTokenDetailsByReportId(query.get("id"));
        if (response) {
          setResponseConfig(response);
          console.log({ ...responseConfig });
        }
      }
      getEmbedData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div className='reportPage'>
            <PowerBIEmbed
                embedConfig={{
                    //hostname: "https://app.powerbigov.us/",
                    type: "report", // Supported types: report, dashboard, tile, visual and qna
                    id: responseConfig.reportId,
                    embedUrl: responseConfig.embedUrl,
                    accessToken: responseConfig.embedToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            },
                            pageNavigation: {
                                visible: false,
                            },
                        },
                        background: models.BackgroundType.Transparent,
                    },
                }}
                eventHandlers={
                    new Map([
                        [
                            "loaded",
                            function () {
                                console.log("Report loaded");
                            },
                        ],
                        [
                            "rendered",
                            function () {
                                console.log("Report rendered");
                            },
                        ],
                        [
                            "error",
                            function (event) {
                                console.log(event.detail);
                            },
                        ],
                    ])
                }
                cssClassName={"report-style-class"}
            />
        </div>
    );
}


export default PowerBiReport;