const About = () => {
    return (

        // <div style={{width:"100%",height:"100vh",backgroundColor:"green"}}>about page</div>
        <div style={{width:"100%",height:"100vh",backgroundColor:"green"}}>
            <img style={{width:"100%",height:"100vh"}} src={require('./powerbi.jpg')} alt='report'></img>
        </div>
    );
}

export default About;