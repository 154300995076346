import sha256 from "crypto-js/sha256";
import qs from "qs";
import base64 from "crypto-js/enc-base64";
import { httpService } from "./httpService";
import { tokenManager } from "./token-manager";
import { store } from "../StateManagement/store";
import { triggerNotification, setAccessToken, setRefreshToken, setIdToken } from "../StateManagement/actions";
import { Buffer } from "buffer";

class LoginService {

  CODE_VERIFIER = "";
  CODE_CHALLENGE = "";
  ACCESS_TOKEN = "";
  REFRESH_TOKEN = "";
  ID_TOKEN = "";

  PING = {
    authorizationGrantType: "authorization_code",
    baseUrl: process.env.REACT_APP_PING_BASE_URL,
    clientId: process.env.REACT_APP_PING_CLIENT_ID,
    codeChallengeMethod: "S256",
    redirectUrl: process.env.REACT_APP_REDIRECT_URL,
    refreshGrantType: "refresh_token",
    responseType: "code"
  }
  CIPM = {
    clientId: process.env.REACT_APP_CIPM_CLIENT_ID,
    secret: process.env.REACT_APP_CIPM_CLIENT_SECRET,
    responseType: "code",
    redirectUrl: process.env.REACT_APP_REDIRECT_URL,
    codeChallengeMethod: "S256",
    state: "46twr7w4tr",
    scope: "openid",
    ui_locales: "en-US",
    baseUrl: process.env.REACT_APP_CIPM_BASE_URL
  }

  /**
    * A function that helps the shell user log into the system by redirecting them to the PING's login portal.
    * This is a PKCE flow, to learn more about it check out this video. https://youtu.be/yf2Hge3VHKY
    */
  shellUserLogin() {
    this.CODE_CHALLENGE = this.generateCodeChallenge();
    const loginPayload = {
      loginType: "shell",
      codeVerifier: this.CODE_VERIFIER,
    };
    localStorage.setItem("loginPayload", JSON.stringify(loginPayload));

    const {
      baseUrl,
      clientId,
      redirectUrl,
      responseType,
      codeChallengeMethod,
      authorizationGrantType,
    } = this.PING;
    const URL = `${baseUrl}/as/authorization.oauth2?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=${responseType}&code_challenge=${this.CODE_CHALLENGE}&code_challenge_method=${codeChallengeMethod}&grant_type=${authorizationGrantType}`;
    window.location.replace(URL);
  }

  nonShellUserLogin() {
    this.CODE_CHALLENGE = this.generateCodeChallenge();
    const loginPayload = {
      loginType: "nonshell",
      codeVerifier: this.CODE_VERIFIER,
    };
    localStorage.setItem("loginPayload", JSON.stringify(loginPayload));

    const { baseUrl, clientId, redirectUrl } = this.CIPM;

    const URL = `${baseUrl}/authorize?redirect_uri=${redirectUrl}&client_id=${clientId}&response_type=code&state=1VWppk8gvD&scope=openid&prompt=consent&access_type=offline&ui_locales=en-US`;
    window.location.replace(URL);
  }

  /**
  * This function continues the login flow after redirection.
  * i.e When the user is redirected back to the website after they've logged into PING.
  */
  async handelUserAfterPINGRedirection(code = null, codeVerifier = null) {
    if (!code || !codeVerifier) return null;
    const shellToken = await this.tryFetchShellToken(code, codeVerifier);
    if (shellToken) {
      const decodedToken = tokenManager.decodeToken(shellToken);
      if (decodedToken && decodedToken.hasOwnProperty("uid")) {
        // const UID = decodedToken.uid;
        // const shellUser = await userService.fetchUser(UID);
        // if (shellUser && shellUser.hasOwnProperty("UserFirstName")) {
        //   store.dispatch(
        //     triggerNotification({
        //       type: {
        //         style: "success",
        //         icon: false,
        //       },
        //       message: `Welcome ${shellUser.UserFirstName}. You're now logged in.`,
        //     })
        //   );
        //   store.dispatch(setUserScreenList(shellUser.ScreenList));
        //   return shellUser;
        // }
        this.updateLoginPayload();
        return {email:decodedToken.mail};
      }
    }
    store.dispatch(
      triggerNotification({
        type: {
          style: "success",
          icon: true,
        },
        message: `Unable to fetch user...`,
      })
    );
    return null;
  }

  /**
     * This function continues the login flow after redirection.
     * i.e When the user is redirected back to the website after they've logged into CIPM.
     */
  async handelUserAfterCIPMRedirection(code = null, codeVerifier = null) {
    if (!code || !codeVerifier) return null;
    const nonShellToken = await this.tryFetchNonShellToken(code, codeVerifier);
    if (nonShellToken) {
      const decodedToken = tokenManager.decodeToken(nonShellToken);
      if (decodedToken && decodedToken.hasOwnProperty("sub")) {
        // const UID = decodedToken.sub;
        // const nonShellUser = await userService.fetchUser(UID);
        // if (nonShellUser && nonShellUser.hasOwnProperty("UserFirstName")) {
        //   store.dispatch(
        //     triggerNotification({
        //       type: {
        //         style: "success",
        //         icon: false,
        //       },
        //       message: `Welcome ${nonShellUser.UserFirstName}. You're now logged in.`,
        //     })
        //   );

        //   return nonShellUser;
        // }
        this.updateLoginPayload();
        //return decodedToken;
        return {email:decodedToken.email};
      }
    }
    store.dispatch(
      triggerNotification({
        type: {
          style: "success",
          icon: true,
        },
        message: `Unable to fetch user...`,
      })
    );
    return null;
  }

  async tryFetchShellToken(code = null, codeVerifier = null) {
    const { clientId, redirectUrl, authorizationGrantType, baseUrl } =
      this.PING;

    const payload = {
      client_id: clientId,
      code: code,
      code_verifier: codeVerifier,
      redirect_uri: redirectUrl,
      grant_type: authorizationGrantType,
    };

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    const tokenUrl = `${baseUrl}/as/token.oauth2`;

    try {
      const response = await httpService.post(
        tokenUrl,
        qs.stringify(payload),
        headers
      );
      if (response && response.data) {
        this.ACCESS_TOKEN = response.data.access_token;
        this.REFRESH_TOKEN = response.data.refresh_token;

        store.dispatch(setAccessToken(this.ACCESS_TOKEN));
        store.dispatch(setRefreshToken(this.REFRESH_TOKEN));
        this.initiateSilentRefreshOfPingToken();
        return this.ACCESS_TOKEN;
      }
    } catch (err) {
      store.dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: "Something went wrong.",
        })
      );

      console.log(err);
    }
    return null;
  }

  /**
    * A function that exchanges the auth code with PING server to get a JWT token.
    * @param {string} code  - The auth code from you get in query params, after redirection.
    * @param {string} codeVerifier  - The code verifier that got generated when user initiated this login.
    */
  async tryFetchNonShellToken(code = null, codeVerifier = null) {

    const { clientId, redirectUrl, baseUrl } = this.CIPM;

    const URL = `${baseUrl}/token?client_id=${clientId}&grant_type=authorization_code&code=${code}&redirect_uri=${redirectUrl}`;

    try {
      var headers = {
        'Content-Type': 'application/json',
        "Authorization": "Basic " + Buffer.from(`${this.CIPM.clientId}:${this.CIPM.secret}`).toString('base64')
      }
      const response = await httpService.post(URL, {}, headers);
      if (response && response.hasOwnProperty("data")) {
        const { id_token, access_token, refresh_token } = response.data;
        this.ACCESS_TOKEN = access_token;
        this.REFRESH_TOKEN = refresh_token;
        this.ID_TOKEN = id_token;
        store.dispatch(setAccessToken(this.ACCESS_TOKEN));
        store.dispatch(setRefreshToken(this.REFRESH_TOKEN));
        store.dispatch(setIdToken(this.ID_TOKEN));
        this.initiateSilentRefreshOfCipmToken();
        return id_token;
      }
    } catch (err) {
      store.dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: "Something went wrong.",
        })
      );

      console.log(err);
    }
    return null;
  }

  initiateSilentRefreshOfPingToken() {
    setInterval(async () => {
      const { clientId, baseUrl } = this.PING;
      const payload = {
        client_id: clientId,
        grant_type: "refresh_token",
        refresh_token: this.REFRESH_TOKEN,
      };
      const pingTokenUrl = `${baseUrl}/as/token.oauth2`;
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",

      };
      const response = await httpService.post(
        pingTokenUrl,
        qs.stringify(payload),
        headers
      );
      this.REFRESH_TOKEN = response.data.refresh_token;
      this.ACCESS_TOKEN = response.data.access_token;

      store.dispatch(setAccessToken(this.ACCESS_TOKEN));
      store.dispatch(setRefreshToken(this.REFRESH_TOKEN));
    }, 899 * 1000); // Silently refresh the ping token every 15 min
  }

  initiateSilentRefreshOfCipmToken() {
    setInterval(async () => {
      const { clientId, baseUrl, redirectUrl } = this.CIPM;
      const payload = {
        client_id: clientId,
        grant_type: "refresh_token",
        refresh_token: this.REFRESH_TOKEN,
        redirect_uri: redirectUrl,
        code_verifier: this.CODE_VERIFIER,
      };
      const cipmTokenUrl = `${baseUrl}/token`;
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": "Basic " + Buffer.from(`${this.CIPM.clientId}:${this.CIPM.secret}`).toString('base64')
      };
      const response = await httpService.post(
        cipmTokenUrl,
        qs.stringify(payload),
        headers
      );
      this.REFRESH_TOKEN = response.data.refresh_token;
      this.ACCESS_TOKEN = response.data.access_token;

      store.dispatch(setAccessToken(this.ACCESS_TOKEN));
      store.dispatch(setRefreshToken(this.REFRESH_TOKEN));
    }, 3599 * 1000); // Silently refresh the cipm token every 1 hour
  }



  /**
   * This function Generates a code verifier which is a random string of 43 chars, it will be used for the PKCE login flow.
   * @param   {number} length - The length of the string
   */
  generateCodeVerifier(length) {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * This function Generates a code challenge corrosponding to the code verifier. It is used in the PKCE login flow.
   */
  generateCodeChallenge() {
    this.CODE_VERIFIER = this.generateCodeVerifier(43);
    return this.base64URL(sha256(this.CODE_VERIFIER));
  }

  base64URL(verifier) {
    return verifier
      .toString(base64)
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  }

  updateLoginPayload() {
    const loginPayload = JSON.parse(localStorage.getItem("loginPayload"));
    loginPayload.codeVerifier = null;
    localStorage.setItem("loginPayload", JSON.stringify(loginPayload));
  }

}

const loginService = new LoginService();

export { loginService }